import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  Form,
  FormText,
  CustomInput,
} from 'reactstrap';
import {
  BtnOutlineRed,
  BtnOutlineGreen,
  BtnRadiusRed,
  PNewItem,
} from '../../styled-components';
import './NewInvoiceModal.scss';
import DateInput from '../DateInput';
import AutoSuggest from '../Autosuggest';
import { ReactComponent as IconPlus } from '../../images/icn-plus.svg';

const INITIAL_INVOICE_STATE = {
  customer: '',
  admin: {
    _id: '',
    businessName: '',
  },
  estateAgent: {
    _id: '',
    businessName: '',
  },
  supplier: {
    _id: '',
    businessName: '',
  },
  number: '',
  date: '',
  supposedPaymentDate: '',
  gross: '',
  net: '',
  vatCode: '',
  customerType: '',
  customerId: '',
  typeOfManager: '',
};

const NewInvoiceModal = ({
  administrator,
  searchAdministrators,
  searchEstateAgents,
  fetchAdminCondos,
  fetchAdminCustomers,
  condos,
  customersExtra,
  onConfirm,
}) => {
  const [t] = useTranslation('ROYALTY');
  const [modal, setModal] = useState(false);
  const isAdminUser = !!administrator;
  const isEstateAgentUser = !!administrator;
  const INITIAL_ADMIN = isAdminUser
    ? {
        _id: administrator._id,
        businessName: administrator.businessName,
      }
    : { ...INITIAL_INVOICE_STATE.admin };

  const INITIAL_ESTATE_AGENT = isEstateAgentUser
    ? {
        _id: administrator._id,
        businessName: administrator.businessName,
      }
    : { ...INITIAL_INVOICE_STATE.estateAgent };

  const [formValues, setFormValues] = useState({
    ...INITIAL_INVOICE_STATE,
    admin: INITIAL_ADMIN,
    estateAgent: INITIAL_ESTATE_AGENT,
  });

  const [formErrors, setFormErrors] = useState({
    supplier: false,
    customer: false,
    admin: false,
    estateAgent: false,
    number: false,
    date: false,
    supposedPaymentDate: false,
    gross: false,
    net: false,
    vatCode: false,
    accountability: false,
  });

  const [currentCondoAdmin, setCurrentCondoAdmin] = useState({});

  useEffect(() => {
    if (moment(formValues.date).isValid()) {
      setFormValues((state) => ({
        ...state,
        supposedPaymentDate: moment(formValues.date).add(60, 'days').toDate(),
      }));
    }
  }, [formValues.date]);

  const toggle = () => {
    setModal(!modal);
    setFormValues({
      ...INITIAL_INVOICE_STATE,
      admin: INITIAL_ADMIN,
      estateAgent: INITIAL_ESTATE_AGENT,
      ...(isAdminUser && { vatCode: administrator.taxCode }),
    });

    fetchAdminCondos();
  };

  function onInputChange({ target }) {
    setFormValues((state) => ({ ...state, [target.name]: target.value }));
  }

  function onNumericInputChange({ target }) {
    if (/^-?[\d]*\.?[\d]{0,2}$/.test(target.value)) {
      setFormValues((state) => ({
        ...state,
        [target.name]: target.value,
      }));
    }
  }

  const renderAdminSuggestion = ({ businessName = '' }) => businessName;
  const getAdminSuggestionValue = ({ businessName = '' }) => businessName;
  function onAdminSuggestionSelect({ _id, businessName }) {
    setFormValues((state) => ({
      ...state,
      admin: { _id, businessName },
      typeOfManager: 'ADMIN',
    }));

    fetchAdminCondos(_id);
    fetchAdminCustomers(_id);
  }

  function onEstateAgentsSuggestionSelect({ _id, businessName }) {
    setFormValues((state) => ({
      ...state,
      estateAgent: { _id, businessName },
      typeOfManager: 'AGENCY',
    }));

    fetchAdminCondos(_id);
    fetchAdminCustomers(_id);
  }

  const onNewInvoiceConfirm = () => {
    const {
      supplier,
      customer,
      admin,
      estateAgent,
      number,
      date,
      supposedPaymentDate,
      gross,
      net,
      accountability,
    } = formValues;

    const errors = {};

    if (isAdminUser && (!supplier || !supplier._id)) errors.supplier = true;
    if (!customer || customer === '') errors.customer = true;
    if (!admin._id && !estateAgent._id) errors.admin = true;
    if (!estateAgent._id && !admin._id) errors.estateAgent = true;
    if (!number) errors.number = true;
    if (!date) errors.date = true;
    if (!supposedPaymentDate) errors.supposedPaymentDate = true;
    if (!gross) errors.gross = true;
    if (!net) errors.net = true;
    if (!accountability) errors.accountability = true;

    if (Object.keys(errors).length) {
      return setFormErrors(errors);
    }

    isAdminUser
      ? (formValues.supplier = formValues.supplier._id)
      : delete formValues.supplier;

    onConfirm({
      ...formValues,
      admin: formValues.admin._id,
      estateAgent: formValues.estateAgent._id,
      net: parseFloat(formValues.net),
      gross: parseFloat(formValues.gross),
    });

    toggle();
  };

  function listenForEscapeEvent() {
    document.addEventListener('keydown', ({ key }) => {
      if (key === 'Escape') toggle();
    });
  }

  const onSelectCondo = (selectedCondoId) => {
    if (selectedCondoId === '') {
      setFormValues((state) => ({
        ...state,
        customer: '',
        vatCode: '',
        customerType: '',
        customerId: '',
      }));
      formErrors.customer = true;
      return;
    }

    formErrors.customer = false;
    const selectedCondo = condos.filter(
      (condo) => condo._id === selectedCondoId
    )[0];

    setCurrentCondoAdmin(selectedCondo.currentAdmin);

    setFormValues((state) => ({
      ...state,
      customer: selectedCondo.name,
      vatCode: selectedCondo.vatCode,
      customerType: 'condos',
      customerId: selectedCondo._id,
    }));
  };

  const onSelectCustomerExtra = (selectedCustomerExtraId) => {
    if (selectedCustomerExtraId === '') {
      setFormValues((state) => ({
        ...state,
        customer: '',
        vatCode: '',
        customerType: '',
        customerId: '',
      }));
      formErrors.customerExtra = true;
      return;
    }

    formErrors.customerExtra = false;
    const selectedCustomerExtra = customersExtra.filter(
      (customer) => customer._id === selectedCustomerExtraId
    )[0];

    setFormValues((state) => ({
      ...state,
      customer: selectedCustomerExtra.businessName,
      vatCode: selectedCustomerExtra.taxCode,
      customerType: 'customerExtra',
      customerId: selectedCustomerExtra._id,
    }));
  };

  const {
    admin,
    estateAgent,
    number,
    date,
    supposedPaymentDate,
    gross,
    net,
    accountability,
  } = formValues;

  return (
    <React.Fragment>
      <BtnRadiusRed onClick={toggle} type="button">
        <IconPlus />
      </BtnRadiusRed>
      <PNewItem
        onClick={toggle}
        style={{ marginLeft: '7px', marginRight: '0', cursor: 'pointer' }}
      >
        {t('NEW_INVOICE')}
      </PNewItem>

      <Modal
        isOpen={modal}
        className="new-invoice-modal"
        onOpened={listenForEscapeEvent}
      >
        <ModalHeader toggle={toggle}>{t('NEW_INVOICE')}</ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            onNewInvoiceConfirm(formValues);
          }}
        >
          <ModalBody>
            <Row>
              <Col sm="12">
                <p>{t('ADD_MANUAL')}</p>
                <Row>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>{t('AMMINISTRATOR')} *</Label>
                      <AutoSuggest
                        placeholder="Cerca un amministratore..."
                        onFetch={searchAdministrators}
                        onSelect={onAdminSuggestionSelect}
                        renderSuggestion={renderAdminSuggestion}
                        getSuggestionValue={getAdminSuggestionValue}
                        value={admin.businessName}
                        onChange={(businessName) => {
                          if (businessName === '') {
                            setFormValues((state) => ({
                              ...state,
                              admin: INITIAL_ADMIN,
                              typeOfManager: '',
                              customer: '',
                              vatCode: '',
                              customerType: '',
                              customerId: '',
                            }));
                          } else {
                            setFormValues((state) => ({
                              ...state,
                              admin: { ...admin, businessName },
                            }));
                          }
                        }}
                        disabled={
                          formValues.estateAgent.businessName !== ''
                            ? true
                            : false
                        }
                      />
                      {formErrors.admin && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>{t('AGENCY')} *</Label>
                      <AutoSuggest
                        placeholder="Cerca un agenzia..."
                        onFetch={searchEstateAgents}
                        onSelect={onEstateAgentsSuggestionSelect}
                        renderSuggestion={renderAdminSuggestion}
                        getSuggestionValue={getAdminSuggestionValue}
                        value={estateAgent.businessName}
                        onChange={(businessName) => {
                          if (businessName === '') {
                            setFormValues((state) => ({
                              ...state,
                              estateAgent: INITIAL_ESTATE_AGENT,
                              typeOfManager: '',
                              customer: '',
                              vatCode: '',
                              customerType: '',
                              customerId: '',
                            }));
                          } else {
                            setFormValues((state) => ({
                              ...state,
                              estateAgent: { ...estateAgent, businessName },
                            }));
                          }
                        }}
                        disabled={
                          formValues.admin.businessName !== '' ? true : false
                        }
                      />
                      {formErrors.estateAgent && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>{t('CONDO')} *</Label>
                      <CustomInput
                        type="select"
                        id="type"
                        name="customer"
                        onChange={(e) => onSelectCondo(e.target.value)}
                        disabled={
                          formValues.customerType === 'customerExtra'
                            ? true
                            : formValues.estateAgent._id !== ''
                            ? true
                            : false
                        }
                      >
                        <option value="">{t('SELECT_CONDO')}</option>
                        {condos.map((condo) => (
                          <option key={condo._id} value={condo._id}>
                            {`${condo.name} - ${condo.vatCode}`}
                          </option>
                        ))}
                      </CustomInput>
                      {formValues.estateAgent._id === '' &&
                        formValues.admin._id === '' && (
                          <FormText className="error-message">
                            {t('SELECT_ADMIN_ERROR')}
                          </FormText>
                        )}
                      {formValues.admin._id !== '' && condos.length === 0 && (
                        <FormText className="error-message">
                          {t('NO_CONDO_ERROR')}
                        </FormText>
                      )}
                      {formErrors.customer && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>

                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>{t('CUSTOMER_EXTRA')} *</Label>
                      <CustomInput
                        type="select"
                        id="type"
                        name="customerExtra"
                        onChange={(e) => onSelectCustomerExtra(e.target.value)}
                        disabled={
                          formValues.customerType === 'condos' ? true : false
                        }
                      >
                        <option value="">{t('SELECT_CUSTOMER')}</option>
                        {customersExtra.map((customer) => (
                          <option key={customer._id} value={customer._id}>
                            {`${customer.businessName} - ${customer.taxCode}`}
                          </option>
                        ))}
                      </CustomInput>
                      {formValues.estateAgent._id === '' &&
                        formValues.admin._id === '' && (
                          <FormText className="error-message">
                            {t('SELECT_AGENCY_CUSTOMER_ERROR')}
                          </FormText>
                        )}
                      {formValues.admin._id !== '' && condos.length === 0 && (
                        <FormText className="error-message">
                          {t('NO_CUSTOMER_ERROR')}
                        </FormText>
                      )}
                      {formErrors.customer && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>

                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INVOICE_NUMBER')} *</Label>
                      <Input
                        type="text"
                        name="number"
                        value={number}
                        onChange={onInputChange}
                      />
                      {formErrors.number && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('INVOICE_DATE')} *</Label>
                      <DateInput
                        name="date"
                        value={date}
                        onChange={(date) =>
                          setFormValues((state) => ({ ...state, date }))
                        }
                      />
                      {formErrors.date && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('EXPIRY_DATE')} *</Label>
                      <DateInput
                        name="supposedPaymentDate"
                        value={supposedPaymentDate}
                        onChange={(supposedPaymentDate) =>
                          setFormValues((state) => ({
                            ...state,
                            supposedPaymentDate,
                          }))
                        }
                      />
                      {formErrors.supposedPaymentDate && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('CONTACTS:IMPORT_NET')} *</Label>
                      <InputGroup>
                        <Input
                          type="number"
                          step="0.01"
                          placeholder="0.00"
                          name="net"
                          value={net}
                          onChange={onNumericInputChange}
                        />
                        <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                      </InputGroup>
                      {formErrors.net && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('CONTACTS:IMPORT_TO_RECEIVE')} *</Label>
                      <InputGroup>
                        <Input
                          type="number"
                          step="0.01"
                          placeholder="0.00"
                          name="gross"
                          value={gross}
                          onChange={onNumericInputChange}
                        />
                        <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                      </InputGroup>
                      {formErrors.gross && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>

                  <Col xs="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('ACC_DATE')} *</Label>
                      <DateInput
                        name="accountability"
                        value={accountability}
                        showOnlyMonth
                        setMidday
                        onChange={(accountability) => {
                          setFormValues((state) => ({
                            ...state,
                            accountability,
                          }));
                        }}
                      />
                      {formErrors.accountability && (
                        <FormText className="error-message">
                          {t('COMMON:REQUIRED')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default NewInvoiceModal;
