import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import {
  BtnModalTable,
  BtnOutlineGreen,
  H5Styled,
} from '../../../styled-components';
import { CurrencyField } from '../../../commons/DataTable';
import ModalConfirm from '../../../commons/ModalConfirm';
import currency from 'currency.js';

const ExtraInvoicesStudio = ({
  data,
  affiliation,
  invoiceStudio,
  retrocession,
}) => {
  const [t] = useTranslation('ENERGY');

  const getTotals = (data) => {
    const affiliationInvoiceNumber =
      affiliation?.pinvInvoice[0]?.invoicePinv?.status === 'delivered'
        ? affiliation?.pinvInvoice[0]?.invoicePinv?.fullnumber || ''
        : '';

    const retrocessionTotals = retrocession.retrocessionTotals;
    const retrocessionToInvoiceTotal = retrocession.retrocessionToInvoice;
    let retrocessionInvoicedTotals = retrocession.retrocessionInvoiced;

    retrocessionInvoicedTotals =
      retrocessionInvoicedTotals > affiliation?.totalNet
        ? retrocessionInvoicedTotals - affiliation?.totalNet
        : 0;

    const affiliationInvoiced =
      affiliation?.pinvInvoice[0]?.invoicePinv?.status === 'delivered'
        ? affiliation?.pinvInvoice[0]?.invoicePinv?.total_price?.subtotal || 0
        : 0;

    let toInvoice = 0;

    if (affiliation) {
      toInvoice =
        retrocessionTotals > affiliation.totalNet
          ? retrocessionTotals -
            retrocessionInvoicedTotals -
            affiliation.totalNet
          : 0;
    } else {
      toInvoice = retrocessionToInvoiceTotal;
    }

    return {
      retrocessionTotals: currency(retrocessionTotals).value,
      retrocessionInvoicedTotals: currency(retrocessionInvoicedTotals).value,
      retrocessionToInvoiceTotal: currency(retrocessionToInvoiceTotal).value,
      affiliationInvoiced: currency(affiliationInvoiced).value,
      affiliationToInvoice:
        affiliationInvoiced > 0 ? 0 : currency(affiliation?.totalNet).value,
      toInvoice: currency(toInvoice).value,
      affiliationInvoiceNumber,
    };
  };

  const totals = getTotals(data);

  return (
    <>
      <Row>
        <Col xs="12">
          <H5Styled>Saldo</H5Styled>
        </Col>
      </Row>
      <br />
      <div className="summary-table">
        <Row className="heading-row">
          <Col xs="3"></Col>
          <Col xs="3">
            <p>Importo</p>
          </Col>
          <Col xs="3">
            <p>Fatturato</p>
          </Col>
          <Col xs="3">
            <p>Da fatturare</p>
          </Col>
        </Row>
        <Row className="normal-row" key={1}>
          <Col xs="3">
            <p>Affiliazione</p>
          </Col>
          <Col xs="3">
            <p>
              <CurrencyField
                value={affiliation?.totalNet}
                className="txt-table-right"
              />
            </p>
          </Col>
          <Col xs="3">
            <p>
              <CurrencyField
                value={totals.affiliationInvoiced}
                className="txt-table-right"
              />
              {totals.affiliationInvoiceNumber && (
                <span>&nbsp;&nbsp;({totals.affiliationInvoiceNumber})</span>
              )}
            </p>
          </Col>
          <Col xs="3">
            <p>
              <CurrencyField
                value={totals.affiliationToInvoice}
                className="txt-table-right"
              />
            </p>
          </Col>
        </Row>
        <Row className="normal-row" key={2}>
          <Col xs="3">
            <p>Retrocessione</p>
          </Col>
          <Col xs="3">
            <p>
              <CurrencyField
                value={totals.retrocessionTotals}
                className="txt-table-right"
              />
            </p>
          </Col>
          <Col xs="3">
            <p>
              <CurrencyField
                value={totals.retrocessionInvoicedTotals}
                className="txt-table-right"
              />
            </p>
          </Col>
          <Col xs="3">
            <p>
              <CurrencyField
                value={totals.toInvoice}
                className="txt-table-right"
              />
            </p>
          </Col>
        </Row>
        <Row className="highlighted-row" key={4}>
          <Col xs="3">
            <p>Totale</p>
          </Col>
          <Col xs="3">
            <p></p>
          </Col>
          <Col xs="3">
            <p></p>
          </Col>
          <Col xs="3">
            <p>
              {totals.toInvoice > 0 && (
                <ModalConfirm
                  className="float-left"
                  style={{ top: -3, position: 'relative' }}
                  onConfirm={() => {
                    console.log(
                      retrocession.retrocessionToInvoiceIds.filter(Boolean)
                    );
                    invoiceStudio(
                      retrocession.retrocessionToInvoiceIds.filter(Boolean)
                    );
                  }}
                  text={t('CONTACTS:CONFIRM_INVOICE')}
                >
                  <BtnModalTable type="button" className="uppercase">
                    FATTURA
                  </BtnModalTable>
                </ModalConfirm>
              )}
              <CurrencyField
                value={totals.toInvoice}
                className="txt-table-right"
              />
            </p>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ExtraInvoicesStudio;
