import './MyCondos.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import SearchInput from '../../commons/SearchInput';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import BurgerMenu from '../../commons/BurgerMenu';
import { ReactComponent as IconBuilding } from '../../images/icn_buildings.svg';
import { ContFlexRight, ContPaginationPage } from '../../styled-components';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';

import CondosTable from './CondosTable';
import ExportCSVButton from './ExportCSVButton';

const CondosPresentational = ({
  data,
  profile,
  query,
  total,
  onFilter,
  formattedData,
}) => {
  const [t] = useTranslation('CONDOS');
  const { offset, limit } = query;

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:CONDOS')}
            icon={<IconBuilding fill="#ffffff" style={{ height: '25px' }} />}
          />

          <Row className="align-items-center">
            <Col className="">
              <ContFlexRight>
                Numero di condomini:&nbsp;<strong>{total}</strong>
              </ContFlexRight>
            </Col>
            <Col xs={12} style={{ marginBottom: 30 }}>
              <ExportCSVButton data={formattedData} />
            </Col>
          </Row>

          <Row className="search-add-cont m-t-20">
            {!(profile && profile._id) && (
              <Col sm="6" md="6" lg="3" xl="3">
                <SearchInput
                  query={{ search: query && query.admin }}
                  onFilter={({ search }) => onFilter({ admin: search })}
                  label={t('SEARCH:ADMINISTRATOR')}
                />
              </Col>
            )}
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.name }}
                onFilter={({ search }) => onFilter({ name: search })}
                label={t('SEARCH:NAME')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.vatCode }}
                onFilter={({ search }) => onFilter({ vatCode: search })}
                label={t('SEARCH:VAT_CODE')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.address }}
                onFilter={({ search }) => onFilter({ address: search })}
                label={t('SEARCH:ADDRESS')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.studioManager }}
                onFilter={({ search }) => onFilter({ studioManager: search })}
                label={t('SEARCH:STUDIO_MANAGER')}
              />
            </Col>
          </Row>
        </section>

        <section className="m-t-20">
          <CondosTable
            data={data}
            total={total}
            offset={offset}
            limit={limit}
            onFilter={onFilter}
          />

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={query.limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default CondosPresentational;
