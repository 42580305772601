import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { compensi, compensiStudio } from '../../lib/fetch/dashboard';
import DashboardDetailCompensiStudioPresentational from './DashboardDetailCompensiStudioPresentational';
import { useLoading } from '../../commons/Loading';
import { useAuth } from '../../commons/Auth';
import {
  useQueryParams,
  withDefault,
  StringParam,
  NumberParam,
} from 'use-query-params';
import axios from 'axios';
const { REACT_APP_DEFAULT_PAGINATION_LIMIT } = process.env;

function DashboardDetailCompensi(props) {
  const { showLoading, hideLoading } = useLoading();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [{ token } = {}] = useAuth();
  const { t } = useTranslation('DASHBOARD');
  const alert = useAlert();

  const [selectedInvoices, setSelectedInvoices] = useState([]);

  const [query, setQuery] = useQueryParams({
    search: withDefault(StringParam, ''),
    admin: withDefault(StringParam, ''),
    year: NumberParam,
  });

  const { limit, offset, search, paid, admin, year } = query;

  const fetchCompensi = async () => {
    showLoading();

    const { error, headers, data } = await compensiStudio({
      year,
      search,
      token,
      limit,
      offset,
      admin,
    });

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setData(data);
    setSelectedInvoices([]);
    setTotal(Number(headers['x-total-count']));
    hideLoading();
  };

  useEffect(() => {
    fetchCompensi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, search, offset, limit, admin]);

  function onFilter(queryDiff) {
    if (
      (queryDiff.search && queryDiff.search !== search) || // first search
      (search && queryDiff.search === '') || // search deleted
      (queryDiff.admin && queryDiff.admin !== admin) || // first search
      (admin && queryDiff.admin === '') || // search deleted
      (queryDiff.limit && queryDiff.limit !== limit) ||
      (queryDiff.year && queryDiff.year !== year) ||
      (search && queryDiff.year === '')
    ) {
      queryDiff.offset = 0; // return to first page
    }
    setQuery(queryDiff);
  }

  function onSelectChange(_id) {
    if (selectedInvoices.includes(_id)) {
      return setSelectedInvoices((selectedInvoices) =>
        selectedInvoices.filter((invoiceId) => invoiceId !== _id)
      );
    }
    setSelectedInvoices((selectedInvoices) => [...selectedInvoices, _id]);
  }

  const onSelectAll = (data) => {
    setSelectedInvoices(data.map(({ _id }) => _id));
  };

  return (
    <DashboardDetailCompensiStudioPresentational
      data={data}
      year={year}
      query={query}
      total={total}
      onFilter={onFilter}
      selectedInvoices={selectedInvoices}
      onSelectChange={onSelectChange}
      onSelectAll={onSelectAll}
      {...props}
    />
  );
}

export default DashboardDetailCompensi;
