import axios from 'axios';
import qs from 'query-string';
const instance = axios.create();

const find = async ({ token, query }) => {
  const { data, headers } = await instance.get(
    `${process.env.REACT_APP_API_URL}/contracts?${qs.stringify(query)}`,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return { data: data.contracts, total: headers['x-total-count'], formattedCSVContracts: data.formattedCSVContracts };
};

const findResume = async ({ token, query }) => {
  const { data, headers } = await instance.get(
    `${process.env.REACT_APP_API_URL}/contracts/resume?${qs.stringify(query)}`,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return { data, total: headers['x-total-count'] };
};

const findRecurrencyResume = async ({ token, query }) => {
  const { data, headers } = await instance.get(
    `${process.env.REACT_APP_API_URL}/contracts/recurrency-resume?${qs.stringify(query)}`,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return { data, total: headers['x-total-count'] };
};

const findUserContracts = async ({ token, query, userId }) => {
  const { data, headers } = await instance.get(
    `${process.env.REACT_APP_API_URL}/contracts/${userId}?${qs.stringify(
      query
    )}`,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return { data: data.contracts, total: headers['x-total-count'] };
};



const findUserRecurrentContracts = async ({ token, query, userId }) => {
  const { data, headers } = await instance.get(
    `${process.env.REACT_APP_API_URL}/contracts/${userId}/recurrent?${qs.stringify(
      query
    )}`,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return { data: data.contracts, total: headers['x-total-count'] };
};


export const importContracts = async ({ token, file }) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/contracts/import-contracts`,
      data: file,
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

const contractsAPIs = { find, findUserContracts, findUserRecurrentContracts, importContracts, findResume, findRecurrencyResume };

export default contractsAPIs;
