import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col } from 'reactstrap';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import { ReactComponent as IconDashboard } from '../../images/icn_dashboard.svg';
import {
  H4Styled,
  VerticalSpacing,
  BtnRadiusWhite,
  ContFlexLeft,
  ContFlexRight,
  BtnOutlineOrange,
  ContFilterCheckbox,
  ContFilterYear,
  ContPaginationPage,
  BtnOutlineGreen,
} from '../../styled-components';
import Table from './Table';
import { ReactComponent as IconBack } from '../../images/icn-back.svg';
import CSVCompensiExport from './CSVCompensiExport';
import SearchInput from '../../commons/SearchInput';
import ModalInvoiceRef from '../ProfileDetail/Royalties/ModalInvoiceRef';
import { FormGroup } from 'reactstrap';
import CustomInput from 'reactstrap/lib/CustomInput';
import TotalTr from './TotalTr';
import Label from 'reactstrap/lib/Label';
import generateYearOptions from '../../lib/helpers/generateYearOptions';
import generateMonthOptions from '../../lib/helpers/generateMonthOptions';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import createPrintablePage from './createPrintablePage';
import variables from '../../variables';
import ModalSendEmail from './ModalSendEmail';
import { useAlert } from 'react-alert';

function DashboardDetailCompensiPresentational({
  data = [],
  total = 0,
  year,
  query,
  onFilter,
  history,
  selectedInvoices = [],
  onSelectChange,
  onSelectAll,
  handleSendEmails,
  emailOnSelectChange,
}) {
  const [t] = useTranslation('DASHBOARD');
  const [allColumns, setAllColumns] = useState(false);
  const [decoratedData, setDecoratedData] = useState(data);
  const alert = useAlert();

  React.useEffect(() => {
    setDecoratedData(
      data.map((invoice) => ({
        ...invoice,
        extraShare: invoice.extraShare - invoice.commercialShare,
      }))
    );
  }, [data]);

  const [selectAll, setSelectAll] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = ({ selectAll: toggleSelectAll = false } = {}) => {
    if (isOpen) {
      setSelectAll(false);
      return setIsOpen(false);
    }

    setSelectAll(toggleSelectAll);

    const validRows = data.map((row, index) => {
      if (row.checked || toggleSelectAll) {
        return {
          ...row,
          checked: !row.paid,
        };
      }
      return row;
    });

    const checkedRows = validRows.filter(({ checked }) => checked);

    if (checkedRows.length) {
      setIsOpen(!isOpen);
    } else {
      const message = toggleSelectAll
        ? t('ROYALTY:NO_ROW_IAF_SELECTABLE')
        : t('ROYALTY:SELECT_AT_LEAST_ONE');
      alert.error(message);
    }
  };

  const onConfirm = (formData) => {
    const validRows = data.map((row, index) => {
      if (row.checked || selectAll) {
        return {
          ...row,
          checked: !row.paid,
        };
      }
      return row;
    });

    const checkedRows = validRows.filter(({ checked }) => checked);

    const dataForEmail = checkedRows.map(
      ({
        _id,
        accountability,
        admin,
        earnedAdminShare,
        adminShare,
        net,
        supplier,
        customer,
        date,
        adminSharePerc,
        number,
        customerType,
      }) => ({
        _id,
        accountability: accountability || undefined,
        adminId: admin._id,
        earnedAdminShare,
        adminShare,
        net,
        supplierId: supplier._id,
        customer,
        date,
        adminSharePerc,
        number,
        customerType,
      })
    );
    handleSendEmails(dataForEmail);
    toggle();
  };

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:DASHBOARD')}
            icon={<IconDashboard fill={variables.white} />}
          />
        </section>

        <section>
          <ModalSendEmail
            title="Invia Email"
            onConfirm={onConfirm}
            toggle={toggle}
            isOpen={isOpen}
          />
          <Row>
            <Col md="12" lg="6">
              <ContFlexLeft>
                <BtnRadiusWhite onClick={() => history.replace('/dashboard')}>
                  <IconBack fill={variables.primary} />
                </BtnRadiusWhite>

                <H4Styled>{t('ROYALTY:TITLE')}</H4Styled>
              </ContFlexLeft>
            </Col>
            <Col md="12" lg="6">
              <ContFlexRight className="actions-m-resp">
                <BtnOutlineGreen
                  onClick={toggle}
                  className="uppercase"
                  style={{ marginRight: '0.6rem' }}
                >
                  INVIA SOLLECITI
                </BtnOutlineGreen>
                <CSVCompensiExport
                  allColumns={allColumns}
                  data={data}
                  selectedInvoices={selectedInvoices}
                  year={year}
                >
                  <BtnOutlineOrange type="button" className="uppercase">
                    {t('COMMON:CSV_EXPORT')}
                  </BtnOutlineOrange>
                </CSVCompensiExport>
              </ContFlexRight>
            </Col>
          </Row>

          <VerticalSpacing>
            <Form>
              <Row>
                <Col sm="6" md="6" lg="4" xl="4">
                  <ContFilterYear className="no-width">
                    <Row>
                      <Col xs="6">
                        <Label>{t('COMMON:YEAR')}</Label>
                      </Col>
                      <Col xs="6">
                        <FormGroup>
                          <CustomInput
                            type="select"
                            id="selectYear"
                            name="year"
                            value={query && query.year}
                            onChange={({ target: { value: year } }) => {
                              onFilter({ year });
                            }}
                          >
                            {generateYearOptions(true)}
                          </CustomInput>
                        </FormGroup>
                      </Col>
                    </Row>
                  </ContFilterYear>
                </Col>
                <Col sm="6" md="6" lg="4" xl="4">
                  <ContFilterYear className="no-width">
                    <Row>
                      <Col xs="6">
                        <Label>{t('COMMON:TITLE_FILTER_ACC_YEAR')}</Label>
                      </Col>
                      <Col xs="6">
                        <FormGroup>
                          <CustomInput
                            type="select"
                            id="selectAccountabilityYear"
                            name="accountabilityYear"
                            value={query && query.accountabilityYear}
                            onChange={({
                              target: { value: accountabilityYear },
                            }) => {
                              onFilter({ accountabilityYear });
                            }}
                          >
                            {generateYearOptions(true)}
                          </CustomInput>
                        </FormGroup>
                      </Col>
                    </Row>
                  </ContFilterYear>
                </Col>
                <Col sm="6" md="6" lg="4" xl="4">
                  <ContFilterYear className="no-width">
                    <Row>
                      <Col xs="6">
                        <Label>{t('COMMON:TITLE_FILTER_ACC_MONTH')}</Label>
                      </Col>
                      <Col xs="6">
                        <FormGroup>
                          <CustomInput
                            type="select"
                            id="accountabilityMonth"
                            name="accountabilityMonth"
                            disabled={!query.accountabilityYear}
                            value={
                              query && query.accountabilityMonth === null
                                ? ''
                                : query.accountabilityMonth
                            }
                            onChange={({
                              target: { value: accountabilityMonth },
                            }) => onFilter({ accountabilityMonth })}
                          >
                            {generateMonthOptions()}
                          </CustomInput>
                        </FormGroup>
                      </Col>
                    </Row>
                  </ContFilterYear>
                </Col>
              </Row>
              <Row className="compensi-variabili-resp">
                <Col sm="6" md="6" lg="4" xl="4">
                  <SearchInput
                    query={{ search: query && query.supplier }}
                    onFilter={({ search }) => onFilter({ supplier: search })}
                    label={t('SEARCH:BY_SUPPLIER')}
                  />
                </Col>
                <Col sm="6" md="6" lg="4" xl="4">
                  <SearchInput
                    query={{ search: query && query.customer }}
                    onFilter={({ search }) => onFilter({ customer: search })}
                    label={t('SEARCH:CONDOMINIUM')}
                  />
                </Col>
                <Col sm="6" md="6" lg="4" xl="4">
                  <SearchInput
                    query={{ search: query && query.admin }}
                    onFilter={({ search }) => onFilter({ admin: search })}
                    label={t('SEARCH:ADMINISTRATOR')}
                  />
                </Col>
              </Row>
              <Row className="compensi-variabili-resp">
                <Col sm="6" md="6" lg="4" xl="4">
                  <SearchInput
                    query={{ search: query && query.number }}
                    onFilter={({ search }) => onFilter({ number: search })}
                    label={t('SEARCH:INVOICE_NUMBER')}
                  />
                </Col>
                <Col sm="6" md="6" lg="4" xl="4">
                  <SearchInput
                    query={{ search: query && query.invoiceRef }}
                    onFilter={({ search }) => onFilter({ invoiceRef: search })}
                    label={t('SEARCH:INVOICEREF')}
                  />
                </Col>
                <Col sm="12" md="6" lg="3">
                  <ContFilterYear className="no-width">
                    <Row>
                      <Col xs="6">
                        <Label>Stato Fatture</Label>
                      </Col>
                      <Col xs="6">
                        <FormGroup>
                          <CustomInput
                            type="select"
                            id="paid"
                            name="paid"
                            onChange={({ target: { value: paid } }) =>
                              onFilter({ paid })
                            }
                          >
                            <option value="" defaultChecked>
                              Tutti
                            </option>
                            <option value="PAID">Pagate</option>
                            <option value="NOT_PAID">Non Pagate</option>
                          </CustomInput>
                        </FormGroup>
                      </Col>
                    </Row>
                  </ContFilterYear>
                </Col>
                <Col sm="12" md="12" lg="4" xl="4">
                  <FormGroup>
                    <ContFilterCheckbox>
                      <CustomInput
                        type="checkbox"
                        id="gabettiInvoiceCheckbox"
                        label="Senza fattura di riferimento"
                        inline
                        checked={query && query.gabettiInvoice === 'false'}
                        onChange={({ target: { checked } }) => {
                          onFilter({ gabettiInvoice: checked ? 'false' : '' });
                        }}
                      />
                    </ContFilterCheckbox>
                  </FormGroup>
                </Col>
              </Row>
            </Form>

            <br />

            <Form>
              <Table
                data={decoratedData}
                total={total}
                onFilter={onFilter}
                onSelectChange={onSelectChange}
                selectedInvoices={selectedInvoices}
                query={query}
                onSelectAll={onSelectAll}
                handleSendEmails={handleSendEmails}
                emailOnSelectChange={emailOnSelectChange}
                bottomRow={<TotalTr data={decoratedData} />}
              />
            </Form>
          </VerticalSpacing>

          <ContPaginationPage>
            <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

            <FormGroup>
              <CustomInput
                type="select"
                className="pagination-select"
                id="perPage"
                name="perPage"
                value={query.limit}
                onChange={({ target: { value: limit } }) => {
                  onFilter({ limit });
                }}
              >
                {generatePaginationOptions()}
              </CustomInput>
            </FormGroup>
          </ContPaginationPage>
        </section>

        <Footer />
      </div>
    </div>
  );
}

export default DashboardDetailCompensiPresentational;
