import './MyCondos.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import SearchInput from '../../commons/SearchInput';
import Header from '../../commons/Header';
import Footer from '../../commons/Footer';
import BurgerMenu from '../../commons/BurgerMenu';
import { ReactComponent as IconBuilding } from '../../images/icn_buildings.svg';
import {
  ContFilterCheckbox,
  ContFlexLeft,
  ContFlexRight,
  ContPaginationPage,
} from '../../styled-components';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';
import generatePaginationOptions from '../../lib/helpers/generatePaginationOptions';
import ModalNewCondo from './ModalNewCondo';
import CondosTable from './CondosTable';
import CondosTableEnergy from './CondosTableEnergy';
import ExportCSVButton from './ExportCSVButton';
import { withRouter } from 'react-router-dom';

const CondosPresentational = ({
  data,
  profile,
  isAdmin,
  query,
  total,
  onFilter,
  onNewCondoConfirm,
  searchAdministrators,
  onCondoUpdate,
  onChangeAdmin,
  onExtendAdminManagement,
  checkVatCode,
  formattedData,
}) => {
  const [t] = useTranslation('CONDOS');
  const { offset, limit } = query;

  return (
    <div>
      <section>
        <Row className="align-items-center">
          <Col sm="12" md="4">
            <ContFlexLeft>
              <ModalNewCondo
                administrator={profile}
                onConfirm={onNewCondoConfirm}
                searchAdministrators={searchAdministrators}
                checkVatCode={checkVatCode}
              />
            </ContFlexLeft>
          </Col>
          <Col className="">
            <ContFlexRight>
              Numero di condomini:&nbsp;<strong>{total}</strong>
            </ContFlexRight>
          </Col>
          <Col xs={12} style={{ marginBottom: 30 }}>
            <ExportCSVButton data={formattedData} />
          </Col>
        </Row>

          <Row className="search-add-cont m-t-20" style={{ rowGap: '10px' }}>
            {!(profile && profile._id) && (
              <Col sm="6" md="6" lg="3" xl="3">
                <SearchInput
                  query={{ search: query && query.admin }}
                  onFilter={({ search }) => onFilter({ admin: search })}
                  label={t('SEARCH:ADMINISTRATOR')}
                />
              </Col>
            )}
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.name }}
                onFilter={({ search }) => onFilter({ name: search })}
                label={t('SEARCH:NAME')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.vatCode }}
                onFilter={({ search }) => onFilter({ vatCode: search })}
                label={t('SEARCH:VAT_CODE')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.address }}
                onFilter={({ search }) => onFilter({ address: search })}
                label={t('SEARCH:ADDRESS')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <SearchInput
                query={{ search: query && query.studioManager }}
                onFilter={({ search }) => onFilter({ studioManager: search })}
                label={t('SEARCH:STUDIO_MANAGER')}
              />
            </Col>
            <Col sm="6" md="6" lg="3" xl="3">
              <FormGroup>
                <ContFilterCheckbox>
                  <CustomInput
                    type="checkbox"
                    id="condosActive"
                    label="Mostra solo gestioni attive"
                    inline
                    checked={query && query.condosActive === 'true'}
                    onChange={(e) =>
                      onFilter({
                        condosActive: e.target.checked ? 'true' : 'false',
                      })
                    }
                  />
                </ContFilterCheckbox>
              </FormGroup>
            </Col>
          </Row>
        </section>

      <section className="m-t-20">
        {!isAdmin ? (
          <CondosTable
            data={data}
            total={total}
            offset={offset}
            limit={limit}
            onFilter={onFilter}
            onCondoUpdate={onCondoUpdate}
            onChangeAdmin={onChangeAdmin}
            profile={profile}
            onExtendAdminManagement={onExtendAdminManagement}
            truncateTable={profile && profile._id}
            checkVatCode={checkVatCode}
          />
        ) : (
          <CondosTableEnergy
            data={data}
            total={total}
            offset={offset}
            limit={limit}
            onFilter={onFilter}
            onCondoUpdate={onCondoUpdate}
            onChangeAdmin={onChangeAdmin}
            profile={profile}
            onExtendAdminManagement={onExtendAdminManagement}
            truncateTable={profile && profile._id}
            checkVatCode={checkVatCode}
          />
        )}

        <ContPaginationPage>
          <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

          <FormGroup>
            <CustomInput
              type="select"
              className="pagination-select"
              id="perPage"
              name="perPage"
              value={query.limit}
              onChange={({ target: { value: limit } }) => {
                onFilter({ limit });
              }}
            >
              {generatePaginationOptions()}
            </CustomInput>
          </FormGroup>
        </ContPaginationPage>
      </section>
      <Footer />
    </div>
  );
};

export default withRouter(CondosPresentational);
