import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import Header from '../../commons/Header';
// import Menu from '../../commons/Menu';
import BurgerMenu from '../../commons/BurgerMenu';
import { useAuth } from '../../commons/Auth';
import { ReactComponent as IconProfile } from '../../images/icn_profilo.svg';
import './MyProfile.scss';
import { Redirect } from 'react-router-dom';
import MyProfilePresentational from './MyProfilePresentational';
import variables from '../../variables';
import { getSignedUrl } from '../../lib/fetch/files';
import { useLoading } from '../../commons/Loading';
import { useAlert } from 'react-alert';
import MyProfileCollabPresentational from './MyProfileCollabPresentational';
import axios from 'axios';

function MyProfile({ location }) {
  const [{ token } = {}] = useAuth();
  const alert = useAlert();
  const [t] = useTranslation();
  const [{ isAdmin, profile = {} } = {}] = useAuth();
  const { showLoading, hideLoading } = useLoading();
  const [adminProfile, setAdminProfile] = useState({});

  async function handleFileListView(key) {
    showLoading();

    const { data, error } = await getSignedUrl(key, token);

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    hideLoading();
    window.open(data.url, '_blank');
  }

  async function fetchAdminForCollab() {
    showLoading();

    try {
      const response = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/collaborators/collab/admin`,
        headers: {
          Authorization: token,
        },
      });

      if (response instanceof Error) {
        hideLoading();
        return { error: response };
      }

      hideLoading();
      setAdminProfile(response.data);
    } catch (error) {
      return { error };
    }
  }

  useEffect(() => {
    if (profile?.type === 'ADMIN_COLLAB') {
      fetchAdminForCollab();
    }
  }, []);

  if (isAdmin)
    return (
      // Admins do not have a profile
      <Redirect
        push={false}
        to={{
          pathname: '/dashboard',
          state: { from: location },
        }}
      />
    );

  return (
    <div className="App">
      {/* <Menu /> */}
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:MY_PROFILE')}
            icon={<IconProfile fill={variables.white} />}
          />
          <Card>
            <CardBody>
              {(profile.type !== 'ADMIN_COLLAB' && (
                <MyProfilePresentational
                  data={profile}
                  handleFileListView={handleFileListView}
                ></MyProfilePresentational>
              )) || (
                <MyProfileCollabPresentational
                  data={profile}
                  adminProfile={adminProfile}
                  handleFileListView={handleFileListView}
                ></MyProfileCollabPresentational>
              )}
            </CardBody>
          </Card>
        </section>
      </div>
    </div>
  );
}

export default MyProfile;
