import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, Field } from '../../commons/DataTable';

function ClientsTable({
  data,
  total,
  offset,
  limit,
  onFilter,
  onChangeAdmin,
  onExtendAdminManagement,
  truncateTable,
  profile,
  onClientUpdate,
  checkVatCode,
}) {
  const [t] = useTranslation('CLIENTS');

  return (
    <DataTable
      data={data}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      emptyText={t('COMMON:NO_DATA')}
    >
      <Field title="Tipo Cliente" source="type" className="text-left" />
      <Field title="Nome" source="name" className="text-left" />
      <Field title="Cognome" source="surname" className="text-left" />
      <Field
        title="Ragione Sociale"
        source="businessName"
        className="text-left"
      />
      <Field title="Telefono" source="phone" className="text-left" />
      <Field title="Email" source="emailReference" className="text-left" />
      <Field title="Partita IVA / CF" source="taxCode" />
    </DataTable>
  );
}

export default ClientsTable;
