import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Form,
  FormText,
} from 'reactstrap';
import {
  BtnOutlineRed,
  BtnOutlineGreen,
  BtnModalTable,
} from '../../styled-components';
import './MyCsutomersExtra.scss';
import { PROVINCE_REGEX } from '../../lib/regex';
import { useAuth } from '../../commons/Auth';

const UpdateCustomerExtraModal = ({
  profile,
  onConfirm,
  defaultValues,
  checkVatCode,
}) => {
  const [t] = useTranslation('CUSTOMER_EXTRA');
  const [modal, setModal] = useState(false);
  const [{ isAdmin, profile: currentProfile = {} } = {}] = useAuth();

  const alert = useAlert();
  const INITIAL_CONDO_STATE = useMemo(
    () => ({
      type: defaultValues.type,
      name: defaultValues.name,
      surname: defaultValues.surname,
      businessName: defaultValues.businessName,
      taxCode: defaultValues.taxCode,
      street: defaultValues.street,
      postalCode: defaultValues.postalCode,
      city: defaultValues.city,
      province: defaultValues.province,
      phone: defaultValues.phone,
      emailReference: defaultValues.emailReference,
    }),
    [defaultValues]
  );

  const [formValues, setFormValues] = useState({
    ...INITIAL_CONDO_STATE,
  });

  const [formErrors, setFormErrors] = useState({
    type: false,
    businessName: false,
    taxCode: false,
    street: false,
    postalCode: false,
    city: false,
    province: false,
    phone: false,
    emailReference: false,
  });

  const toggle = () => {
    setModal(!modal);
    setFormValues({
      ...INITIAL_CONDO_STATE,
    });
  };

  function onInputChange({ target, uppercase }) {
    setFormValues((state) => ({
      ...state,
      [target.name]: uppercase ? target.value?.toUpperCase() : target.value,
    }));
  }

  const onCondoUpdate = async () => {
    const {
      businessName,
      taxCode,
      street,
      city,
      province,
      phone,
      postalCode,
      emailReference,
    } = formValues;

    const errors = {};

    if (!businessName) errors.businessName = true;
    if (!taxCode) errors.taxCode = true;
    if (!street) errors.street = true;
    if (!city) errors.city = true;
    if (!phone) errors.phone = true;
    if (!emailReference) errors.emailReference = true;
    if (!postalCode) errors.postalCode = true;
    if (!province) errors.province = true;
    if (province && !PROVINCE_REGEX.test(province))
      errors.provinceFormat = true;
    if (Object.keys(errors).length) {
      return setFormErrors(errors);
    }

    if (checkVatCode) {
      try {
        const { isDuplicated } = await checkVatCode(formValues.vatCode);
        if (isDuplicated)
          throw new Error('P.IVA / CF già presente nel sistema.');
      } catch (error) {
        alert.error(error.message);
      }
    }

    onConfirm(
      {
        ...formValues,
      },
      defaultValues._id
    );
    toggle();
  };

  function listenForEscapeEvent() {
    document.addEventListener('keydown', ({ key }) => {
      if (key === 'Escape') toggle();
    });
  }

  const {
    type,
    name,
    surname,
    businessName,
    taxCode,
    street,
    postalCode,
    city,
    province,
    phone,
    emailReference,
  } = useMemo(() => formValues, [formValues]);

  return (
    <>
      <React.Fragment>
        <BtnModalTable
          type="button"
          onClick={(event) => {
            event.stopPropagation();
            toggle();
          }}
        >
          {t('EDIT')}
        </BtnModalTable>

        <Modal
          isOpen={modal}
          className="new-customerExtra-modal"
          onOpened={listenForEscapeEvent}
        >
          <ModalHeader toggle={toggle}>{t('NEW_CUSTOMER')}</ModalHeader>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              onCondoUpdate(formValues);
            }}
          >
            <ModalBody>
              <Row>
                <Col sm="12">
                  <Row>
                    <Col xs="12">
                      <FormGroup>
                        <Label>{t('TYPE')}</Label>
                        <Input
                          type="select"
                          name="type"
                          value={type}
                          onChange={onInputChange}
                        >
                          <option value={'AZIENDA'}>AZIENDA</option>
                          <option value={'PRIVATO'}>PRIVATO</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="6" lg="4">
                      <FormGroup>
                        <Label>{t('NAME')}</Label>
                        <Input
                          type="text"
                          name="name"
                          value={name}
                          onChange={onInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="6" lg="4">
                      <FormGroup>
                        <Label>{t('SURNAME')}</Label>
                        <Input
                          type="text"
                          name="surname"
                          value={surname}
                          onChange={onInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="6" lg="4">
                      <FormGroup>
                        <Label>{t('BUSINESS_NAME')} *</Label>
                        <Input
                          type="text"
                          name="businessName"
                          value={businessName}
                          onChange={onInputChange}
                        />
                        {formErrors.businessName && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="6" lg="4">
                      <FormGroup>
                        <Label>{t('TAX_CODE')} *</Label>
                        <Input
                          type="text"
                          name="taxCode"
                          value={taxCode}
                          onChange={onInputChange}
                        />
                        {formErrors.taxCode && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="6" lg="4">
                      <FormGroup>
                        <Label>{t('PHONE')} *</Label>
                        <Input
                          type="text"
                          name="phone"
                          value={phone}
                          onChange={onInputChange}
                        />
                        {formErrors.phone && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="6" lg="4">
                      <FormGroup>
                        <Label>{t('EMAIL')} *</Label>
                        <Input
                          type="email"
                          name="emailReference"
                          value={emailReference}
                          onChange={onInputChange}
                        />
                        {formErrors.emailReference && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="8" md="4">
                      <FormGroup>
                        <Label>{t('STREET')} *</Label>
                        <Input
                          type="text"
                          name="street"
                          value={street}
                          onChange={onInputChange}
                        />
                        {formErrors.street && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs="4" md="2">
                      <FormGroup>
                        <Label>{t('POSTAL_CODE')} *</Label>
                        <Input
                          type="text"
                          name="postalCode"
                          value={postalCode}
                          onChange={onInputChange}
                        />
                        {formErrors.postalCode && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                        {formErrors.postalCodeFormat && (
                          <FormText className="error-message">
                            {t('CUSTOMER_EXTRAS:POSTAL_CODE_FORMAT_ERROR')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs="8" md="4">
                      <FormGroup>
                        <Label>{t('CITY')} *</Label>
                        <Input
                          type="text"
                          name="city"
                          value={city}
                          onChange={onInputChange}
                        />
                        {formErrors.city && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs="4" md="2">
                      <FormGroup>
                        <Label>{t('PROVINCE')} *</Label>
                        <Input
                          type="text"
                          name="province"
                          value={province}
                          onChange={({ target }) =>
                            onInputChange({ target, uppercase: true })
                          }
                          placeholder="es. MI"
                        />
                        {formErrors.province && (
                          <FormText className="error-message">
                            {t('COMMON:REQUIRED')}
                          </FormText>
                        )}
                        {formErrors.provinceFormat && (
                          <FormText className="error-message">
                            {t('CUSTOMER_EXTRAS:PROVINCE_FORMAT_ERROR')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <BtnOutlineRed
                type="button"
                className="uppercase"
                onClick={toggle}
              >
                {t('COMMON:CANCEL')}
              </BtnOutlineRed>
              <BtnOutlineGreen type="submit" className="uppercase">
                {t('COMMON:CONFIRM')}
              </BtnOutlineGreen>
            </ModalFooter>
          </Form>
        </Modal>
      </React.Fragment>
    </>
  );
};
export default UpdateCustomerExtraModal;
