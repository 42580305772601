import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useAlert } from 'react-alert';
import { useLoading } from '../../commons/Loading';
import InsurancesTable from '../Insurances/InsurancesTable';
import {
  BtnOutlineRed,
  BtnOutlineGreen,
  BtnRadiusRed,
  PNewItem,
  BtnModalTable,
} from '../../styled-components';
import './InvoicesByMonth.scss';
import { ReactComponent as IconPlus } from '../../images/icn-plus.svg';
import ExtraInvoicesTabel from './ExtraInvoicesTabel';
import axios from 'axios';
import { useAuth } from '../../commons/Auth';

const MAX_FILE_SIZE_MB = 1; // 1 MB
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024; // B

const ModalExtraInvoice = (props) => {
  const { data = [], month, value, year } = props;
  const [t] = useTranslation('ROYALTY');
  const [{ token } = {}] = useAuth();
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const [modal, setModal] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [invoicesPreview, setInvoicesPreview] = useState([]);
  const [seeButton, setSeeButton] = useState(true);
  const toggle = () => {
    if (modal) {
      setInvoicesPreview([]);
    }
    setModal(!modal);
  };

  const CURRENT_YEAR = new Date().getFullYear();
  const CURRENT_MONTH = new Date().getMonth() + 1;
  const getColor = ({ month, value, year }) => {
    const notNull = value !== null;
    if (notNull || year > CURRENT_YEAR) return '';
    if (year < CURRENT_YEAR) return notNull ? '' : 'red';
    if (month < CURRENT_MONTH) return notNull ? '' : 'red';
    return '';
  };

  const confirmPreview = async () => {
    showLoading();
    const selectedInvoices = invoices.map((elem) => String(elem._id));
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/extra-invoices/${data._id}/invoices`,
      {
        selectedInvoices,
      },
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));

    setInvoices([]);
    setSeeButton(false);
    toggle();
  };

  const listenForEscapeEvent = () => {
    document.addEventListener('keydown', ({ key }) => {
      if (key === 'Escape') toggle();
    });
  };

  const [totExtraGross, setTotExtraGross] = useState(0);
  let totalExtraGross = 0;
  const fetchInvoices = async () => {
    showLoading();
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/invoices-by-month/${data._id}`,
      {
        headers: { Authorization: token },
        params: { month, year },
      }
    );

    if (response instanceof Error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    for (const invoice of response.data) {
      totalExtraGross = totalExtraGross + invoice?.net * 0.15;
    }
    setTotExtraGross(totalExtraGross.toFixed(2));
    setInvoices(
      response.data.map((item) => {
        return { ...item, extraGross: item?.net * 0.15 };
      })
    );
    hideLoading();
  };

  return (
    <React.Fragment>
      <span
        style={{
          color: getColor({ month, value, year }),
          cursor: 'pointer',
        }}
      >
        <span>Totale Fatture: {value || 0}</span>
        {data?.invoices[month - 1]?.seeModal === true && seeButton === true && (
          <div className="flex-check">
            <BtnModalTable
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                toggle();
                fetchInvoices();
              }}
            >
              RENDICONTA
            </BtnModalTable>
          </div>
        )}
      </span>

      <Modal
        isOpen={modal}
        className="new-insurance-modal"
        onOpened={listenForEscapeEvent}
      >
        <ModalHeader toggle={toggle}>
          Anteprima Fatture da Rendicontare
          <br />
        </ModalHeader>
        <ModalBody>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h5 style={{ fontFamily: "'Roboto Slab', serif" }}>
              Totale da rendicontare: € {totExtraGross}
            </h5>
            <h5 style={{ fontFamily: "'Roboto Slab', serif" }}>
              Fatture da Rendicontare: {invoices.length}
            </h5>
          </div>
          {invoices.length > 0 ? (
            <ExtraInvoicesTabel
              data={invoices}
              onFilter={() => {}}
              query={{
                limit: invoices.length,
                offset: 0,
              }}
              total={invoices.length}
            />
          ) : (
            <div style={{ textAlign: 'center' }}>
              {' '}
              Non ci sono fatture da rendicontare{' '}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
            {t('COMMON:CANCEL')}
          </BtnOutlineRed>
          {invoices.length > 0 && (
            <BtnOutlineGreen
              type="button"
              className="uppercase"
              onClick={confirmPreview}
            >
              Conferma Rendicontazione
            </BtnOutlineGreen>
          )}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default ModalExtraInvoice;
