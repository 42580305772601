import React, { useState } from 'react';
import { TabContent, TabPane, Form } from 'reactstrap';
import '../../Contracts/MyContracts.scss';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

import { ReactComponent as IconBuilding } from '../../../images/icn_buildings.svg';
import {
  BtnOutlineGreen,
  ContFlexRight,
  ContPaginationPage,
} from '../../../styled-components';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';

import SearchInput from '../../../commons/SearchInput';
import Footer from '../../../commons/Footer';
import Header from '../../../commons/Header';
import generatePaginationOptions from '../../../lib/helpers/generatePaginationOptions';
import ContractsTableEnergyTab from './ContractsTableEnergyTab';
import ModalConfirm from '../../../commons/ModalConfirm';
import ModalInvoiceDetail from '../../../commons/ModalInvoiceDetail';
import currency from 'currency.js';

const EnergyPresentational = ({
  data,
  profile,
  query,
  total,
  onFilter,
  onCondoUpdate,
  onChangeAdmin,
  onExtendAdminManagement,
  checkVatCode,
  selectedContracts,
  onSelectChange,
  onInvoice,
  onSelectAll,
  selectedAll,
  onStatusChanged,
}) => {
  const [t] = useTranslation('CONTRACTS');
  const { offset, limit } = query;

  const [modalState, setModalState] = useState({
    isOpen: false,
    selectedId: null,
  });
  const [totalContractsValue, setTotalContractsValue] = useState(0);

  const onOpenModal = () => {
    const itemsForTotalValue = selectedContracts.map((_id) => {
      return data.find((contract) => contract._id === _id).contract_value;
    });
    let total = 0;
    itemsForTotalValue.forEach((value) => {
      total += value;
    });
    setTotalContractsValue(
      currency(total, { symbol: '€', precision: 2 }).format()
    );
  };

  return (
    <Form>
      <TabContent activeTab={'1'}>
        <TabPane tabId="1">
          <section>
            <Header
              title={t('PAGES:CONTRACTS')}
              icon={<IconBuilding fill="#ffffff" style={{ height: '25px' }} />}
            />

            <Row className="align-items-center">
              <Col className="">
                <ContFlexRight>
                  {t('CONTRACTS_NUMBER')}:&nbsp;<strong>{total}</strong>
                </ContFlexRight>
              </Col>
            </Row>

            <Row className="search-add-cont m-t-20">
              {!(profile && profile._id) && (
                <Col sm="6" md="6" lg="3" xl="3">
                  <SearchInput
                    query={{ search: query && query.admin }}
                    onFilter={({ search }) => onFilter({ admin: search })}
                    label={t('SEARCH:ADMINISTRATOR')}
                  />
                </Col>
              )}
              <Col sm="6" md="6" lg="3" xl="3">
                <SearchInput
                  query={{ search: query && query.pod_pdr }}
                  onFilter={({ search }) => onFilter({ pod_pdr: search })}
                  label={t('SEARCH:POD_PDR')}
                />
              </Col>
              <Col sm="6" md="6" lg="3" xl="3">
                <SearchInput
                  query={{ search: query && query.condo_vat_cf }}
                  onFilter={({ search }) => onFilter({ condo_vat_cf: search })}
                  label={t('SEARCH:CLIENT_VAT_CODE')}
                />
              </Col>
              <Col sm="6" md="6" lg="3" xl="3">
                <SearchInput
                  query={{ search: query && query.condo_admin_cf }}
                  onFilter={({ search }) =>
                    onFilter({ condo_admin_cf: search })
                  }
                  label={t('SEARCH:ADMIN_VAT_CODE')}
                />
              </Col>
            </Row>
          </section>

          <section className="m-t-20">
            <ContractsTableEnergyTab
              data={data}
              total={total}
              offset={offset}
              limit={limit}
              onFilter={onFilter}
              onCondoUpdate={onCondoUpdate}
              onChangeAdmin={onChangeAdmin}
              profile={profile}
              onExtendAdminManagement={onExtendAdminManagement}
              truncateTable={profile && profile._id}
              checkVatCode={checkVatCode}
              selectedContracts={selectedContracts}
              onSelectChange={onSelectChange}
              onSelectAll={onSelectAll}
              selectedAll={selectedAll}
              setModalState={setModalState}
            />

            <ContPaginationPage>
              <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

              <FormGroup>
                <CustomInput
                  type="select"
                  className="pagination-select"
                  id="perPage"
                  name="perPage"
                  value={limit}
                  onChange={({ target: { value: limit } }) => {
                    onFilter({ limit: limit });
                  }}
                >
                  {generatePaginationOptions()}
                </CustomInput>
              </FormGroup>
            </ContPaginationPage>

            <ModalInvoiceDetail
              modalState={modalState}
              setModalState={setModalState}
              onStatusChanged={onStatusChanged}
            />

            <ContFlexRight className="mt-5">
              {selectedContracts.length > 0 && (
                <ModalConfirm
                  onConfirm={() => {
                    onInvoice();
                  }}
                  onOpen={onOpenModal}
                  text={
                    `Il valore totale dei contratti é: ${totalContractsValue} ` +
                    '\n' +
                    t('CONTACTS:CONFIRM_INVOICE')
                  }
                >
                  <BtnOutlineGreen type="button" className="uppercase">
                    FATTURA SELEZIONATI
                  </BtnOutlineGreen>
                </ModalConfirm>
              )}
            </ContFlexRight>
          </section>
          <Footer />
        </TabPane>
      </TabContent>
    </Form>
  );
};

export default EnergyPresentational;
