import './Invoices.scss';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useAlert } from 'react-alert';
import {
  useQueryParams,
  StringParam,
  withDefault,
  NumberParam,
} from 'use-query-params';
import { useLoading } from '../../commons/Loading';
import InvoicesPresentational from './InvoicesPresentational';
import invoicesAPIs from '../../lib/fetch/invoicesAPI';

function Invoices({ profile }) {
  const [invoices, setInvoices] = useState([]);
  const [condos, setCondos] = useState([]);
  const [customers, setCustomers] = useState([]);

  const [{ token } = {}] = useAuth();
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();

  const [query, setQuery] = useQueryParams({
    all: withDefault(StringParam, 'false'),
    admin: withDefault(StringParam, ''),
    customer: withDefault(StringParam, ''),
    number: withDefault(StringParam, ''),
    supplier: withDefault(StringParam, ''),
    year: withDefault(NumberParam, new Date().getFullYear()),
    accountabilityYear: NumberParam,
    accountabilityMonth: NumberParam,
  });

  const {
    all,
    admin,
    customer,
    number,
    supplier,
    year,
    accountabilityMonth,
    accountabilityYear,
  } = query;

  useEffect(() => {
    fetchInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    all,
    admin,
    customer,
    number,
    supplier,
    year,
    accountabilityMonth,
    accountabilityYear,
  ]);

  const fetchInvoices = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/invoices/${profile._id}`,
      {
        headers: { Authorization: token },
        params: query,
      }
    );

    if (response instanceof Error) {
      hideLoading();
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    setInvoices(response.data);
    hideLoading();
  };

  const searchAdministrators = async (value) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/profiles/admins`,
      {
        headers: { Authorization: token },
        params: { search: value },
      }
    );

    if (response instanceof Error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return [];
    }

    const { data = [] } = response;
    return data;
  };

  const searchEstateAgents = async (value) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/profiles/estateAgents`,
      {
        headers: { Authorization: token },
        params: { search: value },
      }
    );

    if (response instanceof Error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return [];
    }

    const { data = [] } = response;
    return data;
  };

  const fetchAdminCondos = async (adminId) => {
    if (!adminId) {
      setCondos([]);
      return;
    }

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/condos/${adminId}`,
      {
        params: {
          limit: 300,
        },
        headers: { Authorization: token },
      }
    );

    if (response instanceof Error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return [];
    }

    const { data = [] } = response;

    setCondos(data.condos);
  };

  const fetchAdminCustomers = async (adminId) => {
    if (!adminId) {
      setCustomers([]);
      return;
    }

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/customer-extra?profileId=${adminId}`,
      {
        params: {
          limit: 300,
        },
        headers: { Authorization: token },
      }
    );

    if (response instanceof Error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return [];
    }

    const { data = [] } = response;
    console.log('Customers searched:', data);
    setCustomers(data);
  };

  const onNewInvoiceConfirm = async (data) => {
    if (data.admin === '' && data.estateAgent !== '') {
      data.admin = data.estateAgent;
    }
    delete data.estateAgent;
    showLoading();

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/invoices`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInvoices();
  };

  const onInvoiceUpdate = async (data) => {
    const { _id } = data;
    if (data.admin === '' && data.estateAgent !== '') {
      data.admin = data.estateAgent;
    }
    delete data.estateAgent;
    delete data.invoiceIaf;
    delete data.commercialShare;
    delete data.earnedCommercialShare;
    delete data.billableCommercialShare;
    delete data.studioManager;
    delete data.overdueInvoicesCount;
    delete data.latestOverdueInvoiceDate;
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/invoices/${_id}`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInvoices();
  };

  const onAddPayment = async ({ invoiceId, data }) => {
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/payments`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInvoices();
  };

  const onDeletePayment = async ({ invoiceId, paymentIndex }) => {
    showLoading();

    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/payments/${paymentIndex}`,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInvoices();
  };

  const onValidateInvoice = async (invoiceId) => {
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/invoices/${invoiceId}/validate`,
      { validated: true },
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInvoices();
  };

  const onInvoiceDelete = async (invoiceId) => {
    showLoading();

    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/invoices/${invoiceId}`,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    alert.success(t('COMMON:SUCCESS_UPDATE'));
    fetchInvoices();
  };

  function onFilter(queryDiff) {
    if (queryDiff && typeof queryDiff.search === 'string') {
      localStorage.setItem('myInvoiceSearch', queryDiff.search);
    }
    if (
      (queryDiff.accountabilityYear &&
        queryDiff.accountabilityYear !== accountabilityYear) ||
      queryDiff.accountabilityYear === ''
    ) {
      queryDiff.accountabilityMonth = '';
    }
    setQuery(queryDiff);
  }

  const [hasImportedInvoices, setHasImportedInvoices] = useState(false);
  const [invoicesImportFeedbackData, setInvoicesImportFeedbackData] = useState(
    {}
  );

  const toggleShowImportFeedback = () => setHasImportedInvoices((v) => !v);

  const onImportInvoices = async (file) => {
    showLoading();
    const { error, data } = await invoicesAPIs.importInvoices({
      token,
      file,
    });
    hideLoading();
    toggleShowImportFeedback();
    setInvoicesImportFeedbackData({
      ...data,
      validationErrors: error?.response?.data,
    });
    fetchInvoices();
    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
    } else {
      alert.success(t('COMMON:SUCCESS_UPLOAD'));
    }
  };

  return (
    <InvoicesPresentational
      data={invoices}
      query={query}
      onFilter={onFilter}
      searchAdministrators={searchAdministrators}
      searchEstateAgents={searchEstateAgents}
      fetchAdminCondos={fetchAdminCondos}
      fetchAdminCustomers={fetchAdminCustomers}
      condos={condos}
      customersExtra={customers}
      onNewInvoiceConfirm={onNewInvoiceConfirm}
      onInvoiceUpdate={onInvoiceUpdate}
      onAddPayment={onAddPayment}
      onDeletePayment={onDeletePayment}
      onValidateInvoice={onValidateInvoice}
      onInvoiceDelete={onInvoiceDelete}
      onImportInvoices={onImportInvoices}
      toggleShowImportFeedback={toggleShowImportFeedback}
      invoicesImportFeedbackData={invoicesImportFeedbackData}
      hasImportedInvoices={hasImportedInvoices}
    />
  );
}

export default Invoices;
