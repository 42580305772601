import React, { useEffect, useState } from 'react';
import VariableRevenuesDetailPresentational from './VariableRevenuesDetailPresentational';
import { useDebouncedCallback } from 'use-debounce';
import {
  createBonus,
  getReportsVariableRevenues,
} from '../../../lib/fetch/reports';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { useLoading } from '../../../commons/Loading';
import { useAuth } from '../../../commons/Auth';
import { useTranslation } from 'react-i18next';

const VariableRevenuesDetail = (props) => {
  const { profile = {} } = props;

  const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;
  const { _id: profileId } = profile;
  const { showLoading, hideLoading } = useLoading();
  const [{ token } = {}] = useAuth();
  const [t] = useTranslation('CONTACTS');
  const [reports, setReports] = useState({
    categories: { gnet_admin: {}, supplier: {}, bonuses: { data: [] } },
    totals: {},
  });

  const [total, setTotal] = useState(0);

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
    year: withDefault(NumberParam, new Date().getFullYear()),
    accountabilityYear: NumberParam,
    accountabilityMonth: NumberParam,
    supplier: withDefault(StringParam, ''),
    number: withDefault(StringParam, ''),
  });

  const {
    limit,
    offset,
    search,
    supplier,
    number,
    year,
    accountabilityYear,
    accountabilityMonth,
  } = query;

  const [fetchReportsRevenues] = useDebouncedCallback(async () => {
    showLoading();
    const response = await getReportsVariableRevenues({
      limit,
      offset,
      profileId,
      year,
      accountabilityYear,
      accountabilityMonth,
      token,
      supplier,
      number,
    });

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [] } = response;
    setReports(data);
    setTotal(data.categories?.variableRevenues?.count);
  }, 1000);

  useEffect(() => {
    fetchReportsRevenues();
  }, [
    limit,
    offset,
    year,
    accountabilityYear,
    accountabilityMonth,
    search,
    supplier,
    number,
  ]);

  function onFilter(queryDiff) {
    if (
      (queryDiff.accountabilityYear &&
        queryDiff.accountabilityYear !== accountabilityYear) ||
      queryDiff.accountabilityYear === ''
    ) {
      queryDiff.accountabilityMonth = '';
    }

    if (queryDiff.limit && queryDiff.limit !== limit) {
      queryDiff.offset = 0;
    }
    setQuery(queryDiff);
  }

  async function onAddBonus(bonus) {
    const { error } = await createBonus({
      token,
      bonus: { ...bonus, profileId },
    });

    if (error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    fetchReportsRevenues();
    alert.success(t('COMMON:SUCCESS_CREATE'));
  }

  return (
    <VariableRevenuesDetailPresentational
      data={reports}
      onAddBonus={onAddBonus}
      onFilter={onFilter}
      query={query}
      total={total}
      profileType={profile.type}
      {...props}
    />
  );
};

export default VariableRevenuesDetail;
