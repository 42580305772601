import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
  DataTable,
  Field,
  CurrencyField,
  DateTimeField,
  PaymentsDataField,
  BilledField,
} from '../../../commons/DataTable';
import IAFCustomCheckboxes from '../../../commons/Checkboxes/IAFCustomCheckboxes';
import { useAuth } from '../../../commons/Auth';

function RoyaltyTable({
  dataTable,
  data,
  total = 0,
  query = {},
  onFilter,
  isLoading,
  bottomRow,
  showEmptyTableMessage,
  truncateTable,
  onSelectChange,
  allowedRoles = [],
  subAdmins = false,
}) {
  const [t] = useTranslation('COMMON');
  const [{ isAdmin }] = useAuth();
  const { offset, limit } = query;

  const onCheck = (report) => {
    if (!allowedRoles.includes('superadmin')) {
      return;
    }
    onSelectChange(report);
  };

  console.log('====================================');
  console.log('SOno in royallty table');
  console.log('====================================');

  return (
    <DataTable
      offset={offset}
      limit={limit}
      total={total}
      dataTable={dataTable}
      data={data}
      isLoading={isLoading}
      loadingText={t('LOADING')}
      emptyText={t('NO_DATA')}
      onFilter={onFilter}
      bottomRow={bottomRow}
      showEmptyTableMessage={showEmptyTableMessage}
    >
      {!truncateTable && (
        <IAFCustomCheckboxes
          onSelectChange={onCheck}
          onClick={(e) => e.stopPropagation()}
          visible={isAdmin}
        />
      )}
      {!truncateTable && (
        <Field
          title="Nome Fornitore"
          source="supplier.businessName"
          className="text-left"
        />
      )}
      {!truncateTable && <Field title="Numero Fattura" source="number" />}
      {!truncateTable && (
        <DateTimeField title="Data Fattura" source="date" format="DD/MM/YYYY" />
      )}
      {!truncateTable && (
        <DateTimeField
          title="Mese Rend."
          source="accountability"
          format="MM/YYYY"
        />
      )}
      {!truncateTable && (
        <Field title="Responsabile cliente" source="admin.businessName" />
      )}
      <CurrencyField
        title="Imponibile"
        source="net"
        className="txt-table-right"
      />
      <CurrencyField
        title="Importo da Incassare"
        source="gross"
        className="txt-table-right"
      />
      <PaymentsDataField title="Pagato" source="payments" type="total" />
      <CurrencyField
        title={`Quota responsabile (${subAdmins ? '1' : '10'}%)`}
        source="adminShare"
        className="txt-table-right"
      />
      <CurrencyField
        title="Quota responsabile maturata"
        source="earnedAdminShare"
        className="txt-table-right"
      />
      <CurrencyField
        title="Diritto a Fatturare"
        source="billableAdminShare"
        className="txt-table-right"
      />
      <BilledField
        title="Invitato a Fatturare"
        className="txt-table-right"
        subAdmins={subAdmins}
        visible={!truncateTable && isAdmin}
      />
      <Field
        title="Numero IAF"
        className="txt-table-right"
        source="iafs.0.number"
        visible={!truncateTable && isAdmin}
      />
    </DataTable>
  );
}

export default withRouter(RoyaltyTable);
