import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataTable,
  Field,
  DateTimeField,
  BooleanField,
} from '../../commons/DataTable';
import ActionsField from './ActionsField';
import { useAuth } from '../../commons/Auth';

function CondosTable({
  data,
  total,
  offset,
  limit,
  onFilter,
  onChangeAdmin,
  onExtendAdminManagement,
  truncateTable,
  profile,
  onCondoUpdate,
  checkVatCode,
}) {
  const [t] = useTranslation('IAF_INVOICES');

  return (
    <DataTable
      data={data}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      emptyText={t('COMMON:NO_DATA')}
    >
      <Field
        title="Amministratore in Carica"
        source="currentAdmin.name"
        className="text-left"
        visible={!truncateTable}
      />
      <Field title="Nome" source="name" className="text-left" />
      <Field title="Indirizzo" source="address" className="text-left" />
      <Field title="Partita IVA" source="vatCode" />
      <Field title="Numero unità fabbricato" source="manufacturedUnitNumber" />
      <Field title="Numero di studio" source="studioNumber" />
      <Field title="Gestore studio" source="studioManager" />
      <DateTimeField
        title="Inizio gestione"
        source="currentAdmin.startDate"
        format="DD/MM/YYYY"
      />
      <DateTimeField
        title="Fine gestione"
        source="currentAdmin.endDate"
        format="DD/MM/YYYY"
      />
      <BooleanField title="Gestione scaduta" source="currentAdmin.expired" />
      <Field title="N. POD" source="lightContracts.length" />
      <Field title="N. PDR" source="gasContracts.length" />

      <ActionsField
        title="Modifica gestione"
        onChangeAdmin={onChangeAdmin}
        onCondoUpdate={onCondoUpdate}
        profile={profile}
        data={data}
        onExtendAdminManagement={onExtendAdminManagement}
        truncateTable={truncateTable}
        checkVatCode={checkVatCode}
      />
    </DataTable>
  );
}

export default CondosTable;
