import React from 'react';
import { TabContent, TabPane, Form } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';

import { ReactComponent as IconBuilding } from '../../../images/icn_buildings.svg';
import { ContFlexRight, ContPaginationPage } from '../../../styled-components';
import FormGroup from 'reactstrap/lib/FormGroup';
import CustomInput from 'reactstrap/lib/CustomInput';

import SearchInput from '../../../commons/SearchInput';
import ContractsTableEnergyTab from './ContractsTableEnergyTab';
import Footer from '../../../commons/Footer';
import Header from '../../../commons/Header';
import generatePaginationOptions from '../../../lib/helpers/generatePaginationOptions';
import { USER_ADMIN_TYPES } from '../../../App';
import ContractsTableEnergyTabBackoffice from './ContractsTableEnergyTabBackoffice';

const EnergyPresentational = ({
  data,
  profile,
  query,
  total,
  onFilter,
  userType = '',
}) => {
  const [t] = useTranslation('CONTRACTS');
  const { offset, limit } = query;

  return (
    <Form>
      <TabContent activeTab={'1'}>
        <TabPane tabId="1">
          <section>
            <Header
              title={t('PAGES:CONTRACTS')}
              icon={<IconBuilding fill="#ffffff" style={{ height: '25px' }} />}
            />

            <Row className="align-items-center">
              <Col className="">
                <ContFlexRight>
                  Numero di contratti:&nbsp;<strong>{total}</strong>
                </ContFlexRight>
              </Col>
            </Row>

            <Row className="search-add-cont m-t-20">
              {!(profile && profile._id) && (
                <Col sm="6" md="6" lg="3" xl="3">
                  <SearchInput
                    query={{ search: query && query.admin }}
                    onFilter={({ search }) => onFilter({ admin: search })}
                    label={t('SEARCH:ADMINISTRATOR')}
                  />
                </Col>
              )}
              <Col sm="6" md="6" lg="3" xl="3">
                <SearchInput
                  query={{ search: query && query.pod_pdr }}
                  onFilter={({ search }) => onFilter({ pod_pdr: search })}
                  label={t('SEARCH:POD_PDR')}
                />
              </Col>
              <Col sm="6" md="6" lg="3" xl="3">
                <SearchInput
                  query={{ search: query && query.condo_vat_cf }}
                  onFilter={({ search }) => onFilter({ condo_vat_cf: search })}
                  label={t('SEARCH:CLIENT_VAT_CODE')}
                />
              </Col>
            </Row>
          </section>

          <section className="m-t-20">
            {(userType === USER_ADMIN_TYPES.SUPER_ADMIN && (
              <ContractsTableEnergyTab
                data={data}
                total={total}
                offset={offset}
                limit={limit}
                onFilter={onFilter}
                userType={userType}
              />
            )) || (
              <ContractsTableEnergyTabBackoffice
                data={data}
                total={total}
                offset={offset}
                limit={limit}
                onFilter={onFilter}
                userType={userType}
              />
            )}

            <ContPaginationPage>
              <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

              <FormGroup>
                <CustomInput
                  type="select"
                  className="pagination-select"
                  id="perPage"
                  name="perPage"
                  value={limit}
                  onChange={({ target: { value: limit } }) => {
                    onFilter({ limit: limit });
                  }}
                >
                  {generatePaginationOptions()}
                </CustomInput>
              </FormGroup>
            </ContPaginationPage>
          </section>
          <Footer />
        </TabPane>
      </TabContent>
    </Form>
  );
};

export default EnergyPresentational;
