import React, { useEffect, useState } from 'react';
import { useAuth } from '../../commons/Auth';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useLoading } from '../../commons/Loading';
import { Link, Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Footer from '../../commons/Footer';
import { Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import {
  BtnRadiusWhite,
  ContFlexLeft,
  H4Styled,
} from '../../styled-components';
import { ReactComponent as IconBack } from '../../images/icn-back.svg';
import classNames from 'classnames';
import variables from '../../variables';
import './CondoDetail.scss';
import './TabsProfile.scss';
import CondoInfo from './CondoInfo';
import axios from 'axios';

function CondosDetail(props) {
  const [{ token, isAdmin, userType = '' } = {}] = useAuth();
  const [t] = useTranslation('CONDOS_DETAIL');
  const [profile, setProfile] = useState({});
  const [pageBack, setPageBack] = useState('');
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const { history, location, match = {} } = props;
  const { params: { id } = {}, url = '' } = match;
  const { pathname = '', state = {} } = location;
  const tab = pathname.split('/').pop();
  const [condo, setCondo] = useState({});

  useEffect(() => {
    if (state.pageBack) setPageBack(state.pageBack);
  }, [state]);

  useEffect(() => {
    fetchCondo(id);
  }, [id]);

  const fetchCondo = async (id) => {
    showLoading();

    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/condos/detail/${id}`,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      hideLoading();

      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setCondo(response.data);
    hideLoading();
  };

  const fetchCertifiers = async (value) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/profiles/certifier`,
      {
        headers: { Authorization: token },
        params: { search: value },
      }
    );

    if (response instanceof Error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return [];
    }

    const { data = [] } = response;
    return data;
  };

  if (pathname === url) {
    return <Redirect to={`${url}/info`} />;
  }

  return (
    <div>
      <Row>
        <Col sm="12" md="6">
          <ContFlexLeft>
            <BtnRadiusWhite
              onClick={() => {
                if (userType === 'SUPER_ADMIN') {
                  history.replace(`/condos`);
                } else {
                  history.replace(`/my-condos`);
                }
              }}
            >
              <IconBack fill={variables.primary} />
            </BtnRadiusWhite>
            <H4Styled>{t('TITLE_DETAIL')}</H4Styled>
          </ContFlexLeft>
        </Col>
      </Row>

      <div className="m-tb-20">
        <Nav tabs>
          <NavItem>
            <NavLink
              tag={Link}
              to={`${match.url}/info`}
              className={classNames({ active: tab === 'info' })}
            >
              {t('GENERAL_INFO')}
            </NavLink>
          </NavItem>
        </Nav>

        <Switch>
          <Route
            path={`${match.url}/info`}
            component={(props) => (
              <CondoInfo
                id={id}
                data={condo}
                profile={profile}
                setProfile={setProfile}
                fetchCertifiers={fetchCertifiers}
                {...props}
              />
            )}
          />
        </Switch>
      </div>
      <Footer />
    </div>
  );
}

export default withRouter(CondosDetail);
