import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { useCookies } from 'react-cookie';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import createInterceptor from '../../lib/helpers/interceptor';
import fetchMe from '../../lib/fetch/me';
import { initialState, authReducer } from './authReducer';

const AuthContext = createContext([{}, () => {}]);

const AuthProvider = ({ children }) => {
  const { t } = useTranslation('LOGIN');
  const alert = useAlert();

  const [cookies = {}, setCookie, removeCookie] = useCookies([
    'token',
    'isAdmin',
    'email',
    'userType',
  ]);
  const { isAdmin: isAdminString, token, email, userType: typeAdmin } = cookies;
  const isAdmin = isAdminString ? isAdminString === 'true' : undefined;
  const userType = typeAdmin ? typeAdmin : undefined;
  const isAuthenticated = !!(token && token.length > 0);

  const [state, dispatch] = useReducer(authReducer, {
    ...initialState,
    token,
    email,
    isAdmin,
    userType,
    isAuthenticated,
  });

  const dispatchFunction = (action = {}) => {
    const { type, token, isAdmin, email, userType } = action;

    switch (type) {
      case 'login':
        setCookie('token', token, {
          maxAge: process.env.REACT_APP_TOKEN_EXPIRATION,
        });
        setCookie('isAdmin', isAdmin, {
          maxAge: process.env.REACT_APP_TOKEN_EXPIRATION,
        });
        setCookie('email', email, {
          maxAge: process.env.REACT_APP_TOKEN_EXPIRATION,
        });
        setCookie('userType', userType, {
          maxAge: process.env.REACT_APP_TOKEN_EXPIRATION,
        });
        break;
      case 'logout':
        removeCookie('token');
        removeCookie('isAdmin');
        removeCookie('email');
        removeCookie('userType');
        break;
      default:
        break;
    }

    return dispatch(action);
  };

  useEffect(() => {
    createInterceptor(401, () => dispatch({ type: 'logout' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const callMe = async () => {
      const { error, data } = await fetchMe(token);

      if (error) {
        dispatch({ type: 'logout' });
        alert.error(t('COMMON:GENERIC_ERROR'));
        return;
      }

      dispatch({ type: 'setProfile', profile: data });
    };

    // isAdmin has not been set yet. Wait for the next effect.
    if (isAdmin === undefined) return;

    if (isAuthenticated) {
      // Admins have no profile
      isAdmin ? dispatch({ type: 'setProfile' }) : callMe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, isAdmin, userType]);

  return (
    <AuthContext.Provider value={[state, dispatchFunction]}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, AuthContext, useAuth };
