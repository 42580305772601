import React, { useState, useEffect } from 'react';
import {
  useQueryParams,
  StringParam,
  withDefault,
  NumberParam,
} from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useAlert } from 'react-alert';
import { useLoading } from '../../commons/Loading';
import ContractsPresentational from './ContractsPresentational';
import contractsAPIs from '../../lib/fetch/contractsAPIs';
const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const ContractsResume = ({ profile }) => {
  const [contracts, setContracts] = useState([]);
  const [total, setTotal] = useState(0);
  const [{ token, isAdmin } = {}] = useAuth();
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
    year: NumberParam,
    pod_pdr: withDefault(StringParam, ''),
    condo_admin_cf: withDefault(StringParam, ''),
    condo_vat_cf: withDefault(StringParam, ''),
    address: withDefault(StringParam, ''),
  });

  const {
    year,
    limit,
    offset,
    admin,
    condo_vat_cf,
    condo_admin_cf,
    pod_pdr,
  } = query;

  function onFilter(queryDiff) {
    if (
      (queryDiff.pod_pdr && queryDiff.pod_pdr !== pod_pdr) || // first search
      (pod_pdr && queryDiff.pod_pdr === '') || // search deleted
      (queryDiff.condo_vat_cf && queryDiff.condo_vat_cf !== condo_vat_cf) || // first search
      (condo_vat_cf && queryDiff.condo_vat_cf === '') || // search deleted
      (queryDiff.condo_admin_cf &&
        queryDiff.condo_admin_cf !== condo_admin_cf) || // first search
      (condo_admin_cf && queryDiff.condo_admin_cf === '') || // search deleted
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0; // return to first page
    }

    setQuery(queryDiff);
  }

  const fetchContracts = async (query) => {
    const { data, total: _total } = await contractsAPIs.findRecurrencyResume({
      token,
      query,
    });
    setTotal(Number(_total || 0));
    setContracts(data.contracts);
  };

  useEffect(() => {
    fetchContracts({
      year,
      pod_pdr,
      condo_vat_cf,
      condo_admin_cf,
      offset,
      limit,
    });
  }, [year, condo_vat_cf, pod_pdr, condo_admin_cf, offset, limit]);

  return (
    <ContractsPresentational
      data={contracts}
      profile={profile}
      query={query}
      total={total}
      onFilter={onFilter}
    />
  );
};

export default ContractsResume;
