import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataTable,
  Field,
  DateTimeField,
  BooleanField,
} from '../../commons/DataTable';
import ActionsField from './ActionsField';
import { useAuth } from '../../commons/Auth';

function CustomersExtraTable({
  data,
  total,
  offset,
  limit,
  onFilter,
  truncateTable,
  profile,
  onCustomerExtraUpdate,
}) {
  const [t] = useTranslation('IAF_INVOICES');

  data = data.map((element) => {
    let referencesNames = '';
    element.referencesDetail.forEach((reference) => {
      referencesNames = referencesNames + reference.businessName + ', ';
    });
    element.referencesNames = referencesNames;
    return element;
  });

  return (
    <DataTable
      data={data}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      emptyText={t('COMMON:NO_DATA')}
    >
      <Field title="Tipo Cliente" source="type" className="text-left" />
      <Field title="Nome" source="name" className="text-left" />
      <Field title="Cognome" source="surname" className="text-left" />
      <Field
        title="Ragione Sociale"
        source="businessName"
        className="text-left"
      />
      <Field title="Telefono" source="phone" />
      <Field title="Email" source="emailReference" />
      <Field title="Partita IVA / CF" source="taxCode" />

      <Field title="Responsabile" source="profile.businessName" />
      <Field title="Referenti" source="referencesNames" />

      <ActionsField
        title="Modifica gestione"
        onCustomerExtraUpdate={onCustomerExtraUpdate}
        profile={profile}
        data={data}
        truncateTable={truncateTable}
      />
    </DataTable>
  );
}

export default CustomersExtraTable;
